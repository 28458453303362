<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left" />
      <div class="level-right">
        <div class="level-item">
          <b-field class="is-light">
            <b-select
              v-model="revenue"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option value="potentialrevenue">
                Potential Revenue
              </option>

              <option value="weightedrevenue">
                Weighted Revenue
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="level-item">
          <b-field class="is-light">
            <b-select
              v-model="bookingswitch"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option :value="true">
                Booking Date
              </option>
              <option :value="false">
                Install Date
              </option>
            </b-select>
          </b-field>
        </div>

        <div
          v-if="hasRole('ROLE_SALES_MANAGER') || hasRole('ROLE_BUSINESS_ADMIN')"
          class="level-item"
        >
          <b-field class="is-light">
            <b-select
              v-model="selected"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option value="all">
                All
              </option>

              <hr class="dropdown-divider">

              <option value="self">
                Self
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="(subordinate, subordinateIndex) in subordinatesList"
                :key="subordinateIndex"
                :value="subordinate.id"
              >
                {{ subordinate.fullname.trim() }}
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="team in teams"
                :key="team.id"
                :value="`team${team.id}`"
              >
                {{ team.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>

    <div class="card is-rounded-card is-spaced-top">
      <header class="card-header">
        <p class="card-header-title">
          {{ revenue === 'potentialrevenue' ? 'potential' : 'weighted' }} revenue forecast by
          {{ bookingswitch ? 'booking date' : 'install date' }}
        </p>
      </header>

      <div class="card-content">
        <highcharts
          v-if="hasData"
          :options="potentialRevenueForecastSeries"
        />

        <div
          v-else
          class="level is-mobile"
        >
          <div class="level-item has-text-centered">
            <div>
              <figure class="image">
                <img
                  src="@/assets/images/no-data.svg"
                  alt="No Data"
                >
              </figure>
              <b-button
                type="is-primary"
                size="is-small"
              >
                no data found
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue';

export default {
  name: 'PotentialRevenueForecast',

  components: {
    highcharts: Chart,
  },

  data: () => ({
    self: localStorage.getItem('user_id'),
    bookingswitch: true,
    selected: '',
    revenue: 'potentialrevenue',
  }),

  computed: {
    ...mapGetters({
      potentialRevenueForecast: 'Graphs/getPotentialRevenueForecast',
      subordinatesList: 'Users/getSubordinates',
      teams: 'Graphs/getTeams',
    }),

    hasData() {
      const data = { ...this.potentialRevenueForecast };
      return Object.keys(data).length > 0;
    },

    potentialRevenueForecastSeries() {
      const data = { ...this.potentialRevenueForecast };
      const categories = Object.keys(data);

      const thirtyDaySeries = Object.values(data).map((el) => {
        if (el['30-day']) {
          return el['30-day'][this.revenue];
        }
        return 0;
      });
      const sixyDaySeries = Object.values(data).map((el) => {
        if (el['60-day']) {
          return el['60-day'][this.revenue];
        }
        return 0;
      });
      const ninetyDaySeries = Object.values(data).map((el) => {
        if (el['90-day']) {
          return el['90-day'][this.revenue];
        }
        return 0;
      });
      const oneEightDaySeries = Object.values(data).map((el) => {
        if (el['180-day']) {
          return el['180-day'][this.revenue];
        }
        return 0;
      });

      const result = (range) => {
        let date = new Date().setDate(
          new Date().getDate() + range,
        );
        date = new Date(date).toISOString().substring(0, 10);
        return date;
      };

      const thirtyDay = result(30);
      const sixtyDay = result(60);
      const ninetyDay = result(90);
      const oneEightyDay = result(180);

      const openDrilldown = (category, color) => {
        if (this.selected && !(this.selected.toString().startsWith('team') || this.selected === 'all')) {
          if (color === '#1B7DC4') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinbookingdate: `${thirtyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                } : {
                  withininstalldate: `${thirtyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                },
            });
          } else if (color === '#1D3C73') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinbookingdate: `${sixtyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                } : {
                  withininstalldate: `${sixtyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                },
            });
          } else if (color === '#A2CC38') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinbookingdate: `${ninetyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                } : {
                  withininstalldate: `${ninetyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                },
            });
          } else if (color === '#F16510') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinbookingdate: `${oneEightyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                } : {
                  withininstalldate: `${oneEightyDay}`,
                  opportunityowner: category,
                  openswitch: true,
                  ownerid: this.selected === 'self' ? localStorage.getItem('user_id') : this.selected,
                },
            });
          }
        }

        if (color === '#1B7DC4') {
          this.$emit('open-drilldown', {
            name: 'opportunities',
            filters: this.bookingswitch
              ? {
                withinbookingdate: `${thirtyDay}`,
                opportunityowner: category,
                openswitch: true,
              } : {
                withininstalldate: `${thirtyDay}`,
                opportunityowner: category,
                openswitch: true,
              },
          });
        } else if (color === '#1D3C73') {
          this.$emit('open-drilldown', {
            name: 'opportunities',
            filters: this.bookingswitch
              ? {
                withinbookingdate: `${sixtyDay}`,
                opportunityowner: category,
                openswitch: true,
              } : {
                withininstalldate: `${sixtyDay}`,
                opportunityowner: category,
                openswitch: true,
              },
          });
        } else if (color === '#A2CC38') {
          this.$emit('open-drilldown', {
            name: 'opportunities',
            filters: this.bookingswitch
              ? {
                withinbookingdate: `${ninetyDay}`,
                opportunityowner: category,
                openswitch: true,
              } : {
                withininstalldate: `${ninetyDay}`,
                opportunityowner: category,
                openswitch: true,
              },
          });
        } else if (color === '#F16510') {
          this.$emit('open-drilldown', {
            name: 'opportunities',
            filters: this.bookingswitch
              ? {
                withinbookingdate: `${oneEightyDay}`,
                opportunityowner: category,
                openswitch: true,
              } : {
                withininstalldate: `${oneEightyDay}`,
                opportunityowner: category,
                openswitch: true,
              },
          });
        }
      };

      return {
        chart: {
          type: 'bar',
          style: {
            fontFamily: 'Open Sans',
          },
        },

        title: {
          text: null,
        },

        credits: {
          enabled: false,
        },

        colors: ['#1B7DC4', '#1D3C73', '#A2CC38', '#F16510'],

        xAxis: {
          categories,
          allowDecimals: false,
        },

        yAxis: {
          allowDecimals: false,
        },

        plotOptions: {
          series: {
            allowPointSelect: true,
            point: {
              events: {
                select(event) {
                  openDrilldown(event.target.category, event.target.color);
                },
              },
            },
          },
        },

        series: [
          {
            name: '30-day',
            data: thirtyDaySeries,
          },
          {
            name: '60-day',
            data: sixyDaySeries,
          },
          {
            name: '90-day',
            data: ninetyDaySeries,
          },
          {
            name: '180-day',
            data: oneEightDaySeries,
          },
        ],

        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
              ],
            },
          },
        },
      };
    },
  },

  watch: {
    selected: {
      handler(value) {
        if (value === 'all') {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: this.bookingswitch, teamid: '', userid: '',
          });
        } else if (value === 'self') {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: this.bookingswitch, teamid: '', userid: this.self,
          });
        } else if (value.toString().startsWith('team')) {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: this.bookingswitch, teamid: value.toString().substring(4), userid: '',
          });
        } else {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: this.bookingswitch, teamid: '', userid: value,
          });
        }
      },

      deep: true,
    },

    bookingswitch: {
      handler(value) {
        if (this.selected === 'all') {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: value, teamid: '', userid: '',
          });
        } else if (this.selected === 'self') {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: value, teamid: '', userid: this.self,
          });
        } else if (this.selected.toString().startsWith('team')) {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: value, teamid: this.selected.toString().substring(4), userid: '',
          });
        } else {
          this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
            bookingswitch: value, teamid: '', userid: this.selected,
          });
        }
      },

      deep: true,
    },
  },

  async created() {
    try {
      if (this.hasRole('ROLE_SALES_PERSON')) {
        await this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
          bookingswitch: this.bookingswitch,
          teamid: '',
          userid: this.self,
        });
      } else {
        await this.$store.dispatch('Graphs/fetchPotentialRevenueForecast', {
          bookingswitch: this.bookingswitch,
          teamid: '',
          userid: '',
        });
      }
      this.selected = this.hasRole('ROLE_SALES_PERSON') ? 'self' : 'all';
    } catch (error) {
      console.error(error);
    }
  },

};
</script>

<style>

</style>
