<template>
  <div>
    <div class="section">
      <div class="container">
        <users-goals-form
          ref="usersGoalsForm"
          :inline-editing="isEditing"
          @update-goal="updateGoal"
          @fetch-users-goals="fetchUsersGoals"
        />
      </div>
    </div>

    <div class="section">
      <div class="container">
        <users-goals-table
          ref="usersGoalsTable"
          v-click-outside-dg="{
            handler: 'onClickOutsideTable',
            exclude: [
              'usersGoalsForm',
              'inlineEditingSavePrompt',
            ],
          }"
          @is-editing="(val) => isEditing = val"
        />
      </div>
    </div>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateGoal"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { InlineEditingSavePrompt } from '@/components/Shared';
import UsersGoalsForm from '../Organization/Users/UsersGoalsForm.vue';
import UsersGoalsTable from '../Organization/Users/UsersGoalsTable.vue';

export default {
  name: 'ManageGoalsTab',

  components: {
    UsersGoalsForm,
    UsersGoalsTable,
    InlineEditingSavePrompt,
  },

  data: () => ({
    isLoading: true,
    isEditing: false,
    isSavePromptActive: false,
  }),

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
    }),
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    setEditing(value) {
      this.isEditing = value;
    },

    fetchUsersGoals() {
      this.$refs.usersGoalsTable.fetchUsersGoals();
    },

    updateGoal() {
      this.$refs.usersGoalsTable.updateUserGoal();
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.isSavePromptActive = true;
      }
    },

  },
};
</script>

<style lang="css" scoped>
</style>
