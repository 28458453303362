<template>
  <div class="has-background-light columns is-multiline">
    <div class="column is-half">
      <leads-vs-opportunities-by-industry v-on="$listeners" />
    </div>

    <div class="column is-half">
      <open-leads-by-source v-on="$listeners" />
    </div>

    <div class="column is-half">
      <open-leads-by-account v-on="$listeners" />
    </div>

    <div class="column is-half">
      <open-leads-by-stage v-on="$listeners" />
    </div>

    <div class="column is-half">
      <open-opportunities-by-salesperson v-on="$listeners" />
    </div>

    <div class="column is-half">
      <opportunity-date-status v-on="$listeners" />
    </div>

    <div class="column is-half">
      <top-ten-accounts-by-expected-revenue v-on="$listeners" />
    </div>

    <div class="column is-half">
      <weighted-revenue-by-stage v-on="$listeners" />
    </div>

    <div class="column is-half">
      <potential-revenue-forecast v-on="$listeners" />
    </div>

    <div class="column is-half">
      <ytd-sales-vs-goals v-on="$listeners" />
    </div>
  </div>
</template>

<script>
import {
  LeadsVsOpportunitiesByIndustry,
  OpenLeadsByAccount,
  OpenLeadsBySource,
  OpenLeadsByStage,
  OpenOpportunitiesBySalesperson,
  OpportunityDateStatus,
  PotentialRevenueForecast,
  TopTenAccountsByExpectedRevenue,
  WeightedRevenueByStage,
  YtdSalesVsGoals,
} from '@/components/Graphs';

export default {
  name: 'ProfileDashboardTabs',

  components: {
    LeadsVsOpportunitiesByIndustry,
    OpenLeadsByAccount,
    OpenLeadsBySource,
    OpenLeadsByStage,
    OpenOpportunitiesBySalesperson,
    OpportunityDateStatus,
    PotentialRevenueForecast,
    TopTenAccountsByExpectedRevenue,
    WeightedRevenueByStage,
    YtdSalesVsGoals,
  },
};
</script>

<style>

</style>
