<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left" />
      <div class="level-right">
        <div class="level-item">
          <b-field class="is-light">
            <b-select
              v-model="bookingswitch"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option :value="true">
                Booking Date
              </option>
              <option :value="false">
                Install Date
              </option>
            </b-select>
          </b-field>
        </div>
        <div
          v-if="hasRole('ROLE_SALES_MANAGER') || hasRole('ROLE_BUSINESS_ADMIN')"
          class="level-item"
        >
          <b-field class="is-light">
            <b-select
              v-model="selected"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option value="all">
                All
              </option>

              <hr class="dropdown-divider">

              <option value="self">
                Self
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="(subordinate, subordinateIndex) in subordinatesList"
                :key="subordinateIndex"
                :value="subordinate.id"
              >
                {{ subordinate.fullname.trim() }}
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="team in teams"
                :key="team.id"
                :value="`team${team.id}`"
              >
                {{ team.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>

    <div class="card is-rounded-card is-spaced-top">
      <header class="card-header">
        <p class="card-header-title">
          opportunity date status
        </p>
      </header>

      <div class="card-content">
        <highcharts
          v-if="hasData"
          :options="opportunityDateStatusSeries"
        />

        <div
          v-else
          class="level is-mobile"
        >
          <div class="level-item has-text-centered">
            <div>
              <figure class="image">
                <img
                  src="@/assets/images/no-data.svg"
                  alt="No Data"
                >
              </figure>
              <b-button
                type="is-primary"
                size="is-small"
              >
                no data found
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue';

export default {
  name: 'OpportunityDateStatus',

  components: {
    highcharts: Chart,
  },

  data: () => ({
    self: localStorage.getItem('user_id'),
    selected: '',
    bookingswitch: true,
  }),

  computed: {
    ...mapGetters({
      opportunityDateStatus: 'Graphs/getOpportunityDateStatus',
      subordinatesList: 'Users/getSubordinates',
      teams: 'Graphs/getTeams',
    }),

    hasData() {
      const data = [...this.opportunityDateStatus];
      const totalArray = data.map((el) => el.Total);
      const total = totalArray.reduce((a, b) => a + b, 0);
      return data.length > 0 && total > 0;
    },

    opportunityDateStatusSeries() {
      const data = [...this.opportunityDateStatus];
      const series = data.map((el) => ({
        name: el.Name,
        y: el.Total,
      }));

      const result = (range) => {
        let date = new Date().setDate(
          new Date().getDate() + range,
        );
        date = new Date(date).toISOString().substring(0, 10);
        return date;
      };

      const overdue = result(-1);
      const in7days = result(7);
      const in3days = result(3);

      const openDrilldown = (type) => {
        const selectedSubordinate = this.subordinatesList.find(
          (subordinate) => subordinate.id === this.selected,
        );
        if (selectedSubordinate) {
          if (type === 'overdue') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  belowBookingDate: true,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                } : {
                  belowInstallDate: true,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                },
            });
          } else if (type === 'due in 3 days') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in3days}`,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                } : {
                  withinInstallDate: `${in3days}`,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                },
            });
          } else {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in7days}`,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                } : {
                  withinInstallDate: `${in7days}`,
                  openStage: true,
                  owners: this.selected === 'self'
                    ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
                },
            });
          }
        } else if (this.selected.toString().startsWith('team')) {
          if (type === 'overdue') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  beyondBookingDate: true,
                  openStage: true,
                  teamId: this.selected.toString().substring(4),
                } : {
                  overdueinstalldate: true,
                  openStage: true,
                  teamId: this.selected.toString().substring(4),
                },
            });
          } else if (type === 'due in 3 days') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in3days}`,
                  openStage: true,
                  teamId: this.selected.toString().substring(4),
                } : {
                  withinInstallDate: `${in3days}`,
                  openStage: true,
                  teamId: this.selected.toString().substring(4),
                },
            });
          } else {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in7days}`,
                  openStage: true,
                  teamID: this.selected.toString().substring(4),
                } : {
                  withinInstallDate: `${in7days}`,
                  openStage: true,
                  teamID: this.selected.toString().substring(4),
                },
            });
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (type === 'overdue') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${overdue}`,
                  openStage: true,
                } : {
                  withinInstallDate: `${overdue}`,
                  openStage: true,
                },
            });
          } else if (type === 'due in 3 days') {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in3days}`,
                  openStage: true,
                } : {
                  withinInstallDate: `${in3days}`,
                  openStage: true,
                },
            });
          } else {
            this.$emit('open-drilldown', {
              name: 'opportunities',
              filters: this.bookingswitch
                ? {
                  withinBookingDate: `${in7days}`,
                  openStage: true,
                } : {
                  withinInstallDate: `${in7days}`,
                  openStage: true,
                },
            });
          }
        }
      };

      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          style: {
            fontFamily: 'Open Sans',
          },
        },

        credits: {
          enabled: false,
        },

        title: {
          text: null,
        },

        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },

          series: {
            allowPointSelect: true,
            point: {
              events: {
                select(event) {
                  openDrilldown(event.target.name);
                },
              },
            },
          },
        },
        colors: [
          '#A3CC39',
          '#78539D',
          '#3B90D3',
          '#FF6600',
          '#C0CF9A',
          '#FEAB62',
          '#9ABBE0',
          '#B2A0CD',
        ],

        series: [
          {
            name: 'count',
            colorByPoint: true,
            data: series,
          },
        ],

        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
              ],
            },
          },
        },
      };
    },
  },

  watch: {
    selected: {
      handler(value) {
        if (value === 'all') {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: this.bookingswitch, teamid: '', userid: '',
          });
        } else if (value === 'self') {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: this.bookingswitch, teamid: '', userid: this.self,
          });
        } else if (value.toString().startsWith('team')) {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: this.bookingswitch, teamid: value.toString().substring(4), userid: '',
          });
        } else {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: this.bookingswitch, teamid: '', userid: value,
          });
        }
      },

      deep: true,
    },

    bookingswitch: {
      handler(value) {
        if (this.selected === 'all') {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: value, teamid: '', userid: '',
          });
        } else if (this.selected === 'self') {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: value, teamid: '', userid: this.self,
          });
        } else if (this.selected.toString().startsWith('team')) {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: value, teamid: this.selected.toString().substring(4), userid: '',
          });
        } else {
          this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
            bookingswitch: value, teamid: '', userid: this.selected,
          });
        }
      },

      deep: true,
    },
  },

  async created() {
    try {
      if (this.hasRole('ROLE_SALES_PERSON')) {
        await this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
          bookingswitch: this.bookingswitch, teamid: '', userid: this.self,
        });
      } else {
        await this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
          bookingswitch: this.bookingswitch, teamid: '', userid: '',
        });
      }
      if (this.$route.query.withinBookingDate) {
        this.bookingswitch = true;
      } else if (this.$route.query.withinInstallDate) {
        this.bookingswitch = false;
      }
      this.selected = this.hasRole('ROLE_SALES_PERSON') ? 'self' : 'all';
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async fetchOpportunityDateStatus() {
      try {
        await this.$store.dispatch('Graphs/fetchOpportunityDateStatus', {
          bookingswitch: this.bookingswitch, teamid: '', userid: this.self,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

};
</script>

<style>

</style>
