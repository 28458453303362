<template>
  <dashboard-layout>
    <template #content>
      <page-header
        :name="fullName"
        icon="card-account-details-outline"
      >
        <template #actions>
          <div class="level-item">
            <search-bar />
          </div>

          <div class="level-item">
            <b-dropdown aria-role="list">
              <b-button
                slot="trigger"
                type="is-info"
                icon-left="plus"
                icon-right="chevron-down"
              >
                create
              </b-button>
              <template v-if="hasPermissions('create_accounts')">
                <b-dropdown-item
                  has-link
                  aria-role="menuitem"
                >
                  <router-link to="/accounts/add">
                    Account
                  </router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_contacts')">
                <b-dropdown-item
                  has-link
                  aria-role="menuitem"
                >
                  <router-link to="/contacts/add">
                    Contact
                  </router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_leads')">
                <b-dropdown-item
                  has-link
                  aria-role="menuitem"
                >
                  <router-link to="/leads/add">
                    Lead
                  </router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_leads')">
                <b-dropdown-item
                  has-link
                  aria-role="menuitem"
                >
                  <router-link to="/opportunities/add">
                    Opportunity
                  </router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <b-dropdown-item
                v-if="hasPermissions('create_activities')"
                has-link
                aria-role="menuitem"
              >
                <router-link to="/activities/add">
                  Activity
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </page-header>

      <section class="section">
        <b-loading
          v-model="isLoading"
          :is-full-page="false"
        />
        <div class="container">
          <b-tabs v-model="activeTab">
            <b-tab-item label="Overview">
              <profile-overview-tab />
            </b-tab-item>

            <b-tab-item
              :visible="hasRole('ROLE_BUSINESS_ADMIN') || hasRole('ROLE_SALES_MANAGER')"
              label="Teams"
            >
              <div
                v-if="hasPermissions('create_teams')"
                class="content"
              >
                <b-button
                  type="is-info"
                  icon-left="plus"
                  @click="isTeamsFormModalActive = true"
                >
                  Add New Team
                </b-button>
              </div>

              <div class="columns is-multiline">
                <div
                  v-for="(team, index) in teams"
                  :key="index"
                  class="column is-one-third"
                >
                  <teams-card
                    :team="team"
                    @edit-team="editTeam"
                    @delete-team="deleteTeam"
                    @add-team-member="addTeamMember"
                    @delete-team-member="deleteTeamMember"
                  />
                </div>
              </div>

              <hr>

              <b-pagination
                v-model="currentTeamsPage"
                :total="teamsTotal"
                :per-page="perPage"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                @change="onTeamsPageChange"
              />

              <b-modal
                v-model="isTeamsFormModalActive"
                scroll="keep"
                :width="400"
              >
                <teams-form
                  @close="isTeamsFormModalActive = false"
                  @save-team="isEditingTeam ? updateTeam() : createTeam()"
                />
              </b-modal>
            </b-tab-item>

            <b-tab-item label="Dashboard">
              <profile-dashboard-tab @open-drilldown="openDrilldown" />
            </b-tab-item>

            <b-tab-item
              label="My Goals"
            >
              <user-goals-tab />
            </b-tab-item>

            <b-tab-item
              :visible="hasRole('ROLE_BUSINESS_ADMIN') || hasRole('ROLE_SALES_MANAGER')"
              label=" Manage Goals"
            >
              <manage-goals-tab />
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import TeamsMixin from '@/mixins/TeamsMixin';

import { PageHeader } from '@/components/Shared';
import { SearchBar } from '@/components/Search';
import {
  ProfileDashboardTab, ProfileOverviewTab, ManageGoalsTab, UserGoalsTab,
} from '@/components/Profile';
import {
  TeamsCard,
  TeamsForm,
} from '@/components/Organization';

export default {
  name: 'Profile',

  components: {
    DashboardLayout: () => import('../layouts/DashboardLayout.vue'),
    SearchBar,
    PageHeader,
    ProfileDashboardTab,
    ProfileOverviewTab,
    TeamsCard,
    TeamsForm,
    UserGoalsTab,
    ManageGoalsTab,
  },

  mixins: [TeamsMixin],

  data: () => ({
    userId: localStorage.getItem('user_id'),
    fullName: localStorage.getItem('full_name'),
    activeTab: 0,
  }),

  mounted() {
    if (this.hasRole('ROLE_BUSINESS_ADMIN') || this.hasRole('ROLE_SALES_MANAGER')) {
      this.$store.dispatch('Users/fetchSubordinates');
      this.$store.dispatch('Graphs/fetchTeams');
    }
  },

  methods: {
    openDrilldown(value) {
      if (value.name === 'lead') {
        this.$router.push(`/leads?${new URLSearchParams(value.filters).toString()}`);
      } else if (value.name === 'account') {
        this.$router.push(`/accounts?${new URLSearchParams(value.filters).toString()}`);
      } else {
        this.$router.push(`/opportunities?${new URLSearchParams(value.filters).toString()}`);
      }
    },
  },
};
</script>

<style></style>
