var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('users-goals-form',{ref:"usersGoalsForm",attrs:{"inline-editing":_vm.isEditing},on:{"update-goal":_vm.updateGoal,"fetch-users-goals":_vm.fetchUsersGoals}})],1)]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('users-goals-table',{directives:[{name:"click-outside-dg",rawName:"v-click-outside-dg",value:({
          handler: 'onClickOutsideTable',
          exclude: [
            'usersGoalsForm',
            'inlineEditingSavePrompt',
          ],
        }),expression:"{\n          handler: 'onClickOutsideTable',\n          exclude: [\n            'usersGoalsForm',\n            'inlineEditingSavePrompt',\n          ],\n        }"}],ref:"usersGoalsTable",on:{"is-editing":(val) => _vm.isEditing = val}})],1)]),_c('b-modal',{ref:"inlineEditingSavePrompt",attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"inline editing save modal","aria-modal":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('inline-editing-save-prompt',{on:{"save":_vm.updateGoal,"close":props.close}})]}}]),model:{value:(_vm.isSavePromptActive),callback:function ($$v) {_vm.isSavePromptActive=$$v},expression:"isSavePromptActive"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }