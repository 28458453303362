<template>
  <div>
    <div class="section">
      <div class="container">
        <user-goals-table
          ref="usersGoalsTable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { ValidationObserver, ValidationProvider } from 'vee-validate';
import UserGoalsTable from '../Organization/Users/UserGoalsTable.vue';

export default {
  name: 'UserGoalsTab',

  components: {
    UserGoalsTable,
  },

  data: () => ({
    isLoading: true,
    isEditing: false,
    isSavePromptActive: false,
  }),

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
    }),
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    setEditing(value) {
      this.isEditing = value;
    },

    fetchUsersGoals() {
      this.$refs.usersGoalsTable.fetchUsersGoals();
    },

    updateGoal() {
      this.$refs.usersGoalsTable.updateUserGoal();
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.isSavePromptActive = true;
      }
    },

  },
};
</script>

<style lang="css" scoped>
</style>
