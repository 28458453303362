<template>
  <div>
    <div
      v-if="hasRole('ROLE_SALES_MANAGER') || hasRole('ROLE_BUSINESS_ADMIN')"
      class="level is-mobile"
    >
      <div class="level-left" />
      <div class="level-right">
        <div class="level-item">
          <b-field class="is-light">
            <b-select
              v-model="selected"
              placeholder="FILTER BY"
              size="is-small"
            >
              <option value="all">
                All
              </option>

              <hr class="dropdown-divider">

              <option value="self">
                Self
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="(subordinate, subordinateIndex) in subordinatesList"
                :key="subordinateIndex"
                :value="subordinate.id"
              >
                {{ subordinate.fullname.trim() }}
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="team in teams"
                :key="team.id"
                :value="`team${team.id}`"
              >
                {{ team.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>

    <div class="card is-rounded-card is-spaced-top">
      <header class="card-header">
        <p class="card-header-title">
          leads/opportunities by industry
        </p>
      </header>

      <div class="card-content">
        <highcharts
          v-if="hasData"
          :options="leadsVsOpportunitiesByIndustrySeries"
        />

        <div
          v-else
          class="level is-mobile"
        >
          <div class="level-item has-text-centered">
            <div>
              <figure class="image">
                <img
                  src="@/assets/images/no-data.svg"
                  alt="No Data"
                >
              </figure>
              <b-button
                type="is-primary"
                size="is-small"
              >
                no data found
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue';

export default {
  name: 'LeadsVsOpportunitiesByIndustry',

  components: {
    highcharts: Chart,
  },

  data: () => ({
    self: localStorage.getItem('user_id'),
    selected: '',
  }),

  computed: {
    ...mapGetters({
      leadsVsOpportunitiesByIndustry: 'Graphs/getLeadsVsOpportunitiesByIndustry',
      subordinatesList: 'Users/getSubordinates',
      teams: 'Graphs/getTeams',
    }),

    hasData() {
      const data = { ...this.leadsVsOpportunitiesByIndustry };
      return Object.values(data).map((el) => el.Lead).length > 0
      || Object.values(data).map((el) => el.Opportunity) > 0;
    },

    leadsVsOpportunitiesByIndustrySeries() {
      const data = { ...this.leadsVsOpportunitiesByIndustry };
      const categories = Object.keys(data);
      const leadSeries = Object.values(data).map((el) => el.Lead);
      const opportunitySeries = Object.values(data).map((el) => el.Opportunity);

      const openDrilldown = (name, industry) => {
        const selectedSubordinate = this.subordinatesList.find(
          (subordinate) => subordinate.id === this.selected,
        );
        if (selectedSubordinate) {
          this.$emit('open-drilldown', {
            name,
            filters: {
              industry,
              openSwitch: true,
              owners: this.selected === 'self'
                ? localStorage.getItem('full_name') : selectedSubordinate.fullname,
            },
          });
        } else if (this.selected.toString().startsWith('team')) {
          this.$emit('open-drilldown', {
            name,
            filters: {
              industry,
              openSwitch: true,
              teamId: this.selected.toString().substring(4),
            },
          });
        } else {
          this.$emit('open-drilldown', {
            name,
            filters: { industry, openSwitch: true },
          });
        }
      };

      return {
        chart: {
          type: 'bar',
          style: {
            fontFamily: 'Open Sans',
          },
        },

        title: {
          text: null,
        },

        credits: {
          enabled: false,
        },

        colors: ['#3B90D3', '#1E3D73'],

        xAxis: {
          categories,
          allowDecimals: false,
        },

        yAxis: {
          allowDecimals: false,
        },

        plotOptions: {
          series: {
            allowPointSelect: true,

            point: {
              events: {
                select(event) {
                  if (event.target.color === '#3B90D3') {
                    openDrilldown('leads', event.target.category);
                  } else {
                    openDrilldown('opportunity', event.target.category);
                  }
                },
              },
            },
          },
        },

        series: [
          {
            name: 'Leads',
            data: leadSeries,
          },
          {
            name: 'Opportunities',
            data: opportunitySeries,
          },
        ],

        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
              ],
            },
          },
        },
      };
    },
  },

  watch: {
    selected: {
      handler(value) {
        if (value === 'all') {
          this.$store.dispatch(
            'Graphs/fetchLeadsVsOpportunitiesByIndustry',
            { teamid: '', userid: '' },
          );
        } else if (value === 'self') {
          this.$store.dispatch(
            'Graphs/fetchLeadsVsOpportunitiesByIndustry',
            { teamid: '', userid: this.self },
          );
        } else if (value.toString().startsWith('team')) {
          this.$store.dispatch(
            'Graphs/fetchLeadsVsOpportunitiesByIndustry',
            { teamid: value.toString().substring(4), userid: '' },
          );
        } else {
          this.$store.dispatch(
            'Graphs/fetchLeadsVsOpportunitiesByIndustry',
            { teamid: '', userid: value },
          );
        }
      },

      deep: true,
    },
  },

  async created() {
    try {
      if (this.hasRole('ROLE_SALES_PERSON')) {
        await this.$store.dispatch(
          'Graphs/fetchLeadsVsOpportunitiesByIndustry',
          { teamid: '', userid: this.self },
        );
      } else {
        await this.$store.dispatch(
          'Graphs/fetchLeadsVsOpportunitiesByIndustry',
          { teamid: '', userid: '' },
        );
      }

      this.selected = this.hasRole('ROLE_SALES_PERSON') ? 'self' : 'all';
    } catch (error) {
      console.error(error);
    }
  },

};
</script>

<style>

</style>
